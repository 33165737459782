<template>
  <div class="news-list">
    <div
      class="news-list-item"
      v-for="(item, index) in newsList100Text"
      :key="index"
      :style="{
        'animation-delay': `.${index % pageSize}s`,
      }"
    >
      <div class="news-list-img">
        <el-image
          :src="item.imgUrl"
          fit="cover"
          :alt="item.title"
        />
      </div>
      <div class="news-list-r">
        <div class="news-list-title">
          <div
            class="text"
            :title="item.title"
          >
            {{ item.title }}
          </div>
          <div class="data">
            {{ item.pubDate }}
          </div>
        </div>
        <div class="news-list-content">
          {{ item.content }}
        </div>
        <div class="news-list-detail">
          <router-link
            class="news-list-detail-link"
            :to="{
              path: '/newsCenter/newsDetail',
              query: {
                id: item.id,
              },
            }"
          >
            查看详情>>
          </router-link>
        </div>
      </div>
    </div>
    <div class="news-list-more">
      <div
        class="btn"
        @click="loadingMore"
        v-if="!isMoreBtnHide"
      >
        <template v-if="loading">
          <i class="el-icon-loading" />
        </template>
        <template v-else>
          点击加载更多
        </template>
      </div>
      <div
        class="now-more-news"
        v-else
      >
        没有更多新闻
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    newsList: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    pageSize: {
      type: Number,
      default: 10
    },
    isMoreBtnHide: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    newsList100Text () {
      // 去除content中的html标签 并限制长度
      const strNum = 100
      const reg = /<.*?(?:>|\/>)|[&nbsp;]/gi
      return this.newsList.map((item) => {
        let content = item.content ? item.content.replace(reg, '') : ''
        if (content.length > strNum) {
          content = content.substring(0, strNum) + '...'
        }
        return {
          ...item,
          content
        }
      })
    }
  },
  data () {
    return {}
  },
  methods: {
    loadingMore () {
      this.$emit('loadingMore', this.page)
    }
  },
  mounted () {}
}
</script>

<style lang="less" scoped>
@height: 248px;
@width: 360px;
.news-list {
  padding-bottom: 20px;
  width: 100%;
  &-item {
    width: 100%;
    height: @height;
    background: linear-gradient(180deg, #f3f5f8 0%, #fefefe 100%);
    box-shadow: 0px 3px 6px 1px #4978fa1f;
    border-radius: 4px;
    border: 3px solid #ffffff;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
    display: flex;
    animation-name: one-in;
    animation-fill-mode: forwards;
    animation-duration: 0.3s;
    overflow: hidden;
    &:hover {
      box-shadow: 0px 0px 0px 0px #4978fa1f;
    }
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  &-img {
    height: @height;
    width: @width;
    background: @imgBgColor;
    >.el-image{
      width: 100%;
      height: 100%;
    }
  }
  &-r {
    padding: 24px 46px;
    box-sizing: border-box;
    flex: 1;
  }
  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 6px;
    margin-bottom: 32px;
    font-size: 20px;
    color: #000;
    border-bottom: 2px solid #70707036;
    .text {
      max-width: 500px;
      margin-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .data {
      white-space: nowrap;
    }
  }
  &-content {
    height: 100px;
    font-size: 20px;
    font-weight: 300;
    color: #6d7281;
    margin-bottom: 10px;
  }
  &-detail {
    text-align: right;
    &-link {
      font-size: 20px;
      color: #4978fa;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  // 加载更多
  &-more {
    text-align: center;
    .btn {
      display: inline-block;
      width: 300px;
      height: 62px;
      line-height: 62px;
      text-align: center;
      background: #ffffff;
      font-size: 20px;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
      border-radius: 67px;
      cursor: pointer;
      transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
      &:hover {
        background: #3a5fca;
        color: #fff;
      }
    }
    .now-more-news{
      text-align: center;
      font-size: 14px;
      color: #333;
      opacity: 0.5;
    }
  }
}
@keyframes one-in {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: @height;
    opacity: 1;
  }
}
</style>
