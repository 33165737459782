<template>
  <div class="news-search">
    <div
      class="input-box"
      :class="{focus:isFocus}"
    >
      <input
        type="text"
        :placeholder="placeholder"
        @input="getInputValue"
        @blur="isFocus = false"
        @focus="isFocus = true"
      >
      <i
        class="el-icon-search"
        @click="searchBtn"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    inputValue: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isFocus: false
    }
  },
  methods: {
    getInputValue (e) {
      const event = e || window.event
      const target = event.srcElement || event.taget
      this.$emit('update:inputValue', target.value)
    },
    searchBtn () {
      this.$emit('search', this.inputValue)
    }
  }
}
</script>

<style lang="less" scoped>
.news-search {
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  .input-box {
    width: 490px;
    height: 54px;
    background: #ffffff;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.16);
    border: 1px solid #fff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    transition: .3s;
    &.focus{
      border: 1px solid #2D8CF0;
    }
    input {
      flex: 1;
      height: 90%;
      font-size: 20px;
      box-sizing: border-box;
    }
    i {
      font-size: 40px;
      color: #c3c3c3;
      cursor: pointer;
      transition: cubic-bezier(0.075, 0.82, 0.165, 1) .3s;
      &:hover{
        color: #a0b2c5;
      }
    }
  }
}
</style>
