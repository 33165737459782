var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"news-list"},[_vm._l((_vm.newsList100Text),function(item,index){return _c('div',{key:index,staticClass:"news-list-item",style:({
      'animation-delay': ("." + (index % _vm.pageSize) + "s"),
    })},[_c('div',{staticClass:"news-list-img"},[_c('el-image',{attrs:{"src":item.imgUrl,"fit":"cover","alt":item.title}})],1),_c('div',{staticClass:"news-list-r"},[_c('div',{staticClass:"news-list-title"},[_c('div',{staticClass:"text",attrs:{"title":item.title}},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"data"},[_vm._v(" "+_vm._s(item.pubDate)+" ")])]),_c('div',{staticClass:"news-list-content"},[_vm._v(" "+_vm._s(item.content)+" ")]),_c('div',{staticClass:"news-list-detail"},[_c('router-link',{staticClass:"news-list-detail-link",attrs:{"to":{
            path: '/newsCenter/newsDetail',
            query: {
              id: item.id,
            },
          }}},[_vm._v(" 查看详情>> ")])],1)])])}),_c('div',{staticClass:"news-list-more"},[(!_vm.isMoreBtnHide)?_c('div',{staticClass:"btn",on:{"click":_vm.loadingMore}},[(_vm.loading)?[_c('i',{staticClass:"el-icon-loading"})]:[_vm._v(" 点击加载更多 ")]],2):_c('div',{staticClass:"now-more-news"},[_vm._v(" 没有更多新闻 ")])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }