<template>
  <div class="news-center">
    <v-banner-box :data="swiperData">
      <template slot="button">
        <p />
      </template>
    </v-banner-box>
    <v-search
      placeholder="搜索"
      @search="search"
      :input-value.sync="params.title"
    />
    <v-news-type
      :data="newsTypeList"
      :active-index.sync="activeIndex"
    />
    <v-news-list
      :news-list="newsList"
      :loading="loading"
      :page-size="params.limit"
      :is-more-btn-hide="isMoreBtnHide"
      @loadingMore="loadingMore"
    />
  </div>
</template>

<script>
// NOTE :  新闻中心
import vBannerBox from '@/components/banner/bannerBox.vue'
import vSearch from '@/components/searchInput/index.vue'
import vNewsType from './newsType.vue'
import vNewsList from './newList.vue'
export default {
  components: {
    vSearch,
    vNewsType,
    vNewsList,
    vBannerBox
  },
  watch: {
    activeIndex (val) {
      this.params.page = 1
      this.params.title = ''
      this.params.parentId = this.newsTypeList[val].id
      this.newsList = []
      this.getNewsList()
    }
  },
  data () {
    return {
      swiperData: [],
      // 新闻类型
      newsTypeList: [],
      activeIndex: 0,
      // 新闻列表
      newsList: [],
      params: {
        title: '',
        page: 1,
        limit: 10,
        parentId: ''
      },
      isMoreBtnHide: false,
      // 加载状态
      loading: false
    }
  },
  created () {
    this.getSwiperData()
    this.getNewsTypes()
    this.getNewsList()
  },
  methods: {
    search (val) {
      this.params.title = val
      this.getNewsList()
    },
    /**
     * 获取轮播图
     */
    getSwiperData () {
      this.loading = true
      const params = {
        parentId: this.$siteTypes.bannerTypes['新闻中心']
      }
      this.swiperData = []
      this.$api
        .getBannerInfo(params)
        .then(({ data: res }) => {
          this.loading = false
          if (res.code !== 0) {
            return console.error(res)
          }
          this.swiperData = res.data
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
        })
    },
    // 新闻分类获取
    getNewsTypes () {
      const params = {
        parentId: this.$siteTypes.siteTypes['新闻']
      }
      this.$api
        .getSitetypes(params)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return console.error(res)
          }
          const data = {
            id: '',
            title: '全部'
          }
          this.newsTypeList = [data, ...res.data]
        })
        .catch((err) => {
          console.error(err)
        })
    },
    // 新闻列表
    getNewsList (isMore) {
      this.loading = true
      this.$api.getNewsList(this.params).then(({ data: res }) => {
        if (res.code !== 0) {
          return console.error(res)
        }
        this.newsList = isMore ? this.newsList.concat(res.data.list) : res.data.list
        if (res.data.list.length <= this.params.limit) this.isMoreBtnHide = true
        this.loading = false
      }).catch((err) => {
        console.error(err)
      })
    },
    loadingMore () {
      this.params.page++
      this.getNewsList(true)
    }
  }
}
</script>

<style lang="less" scoped>
</style>
