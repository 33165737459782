<template>
  <div class="news-type">
    <VSuspensionImg
      position="left"
      img="/static/img/suspendedBlock/a-1.png"
      :img-style="{ transform: 'translate(-50%,-300%)' }"
    />
    <div class="news-type-list">
      <div
        v-for="(item, index) in data"
        :key="index"
        class="news-type-item"
        :class="{ active: index === activeIndex }"
        @click="handleClick(index)"
      >
        <div class="news-type-item-name">
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {}
  },
  methods: {
    handleClick (index) {
      this.$emit('update:activeIndex', index)
    }
  }
}
</script>

<style lang="less" scoped>
.news-type {
  position: relative;
  padding: 0 0 20px;
  border-bottom: 1px solid #efefef;
  margin-bottom: 20px;
  &-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  &-item {
    background: #ffffff;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.16);
    border-radius: 25px;
    padding: 6px 30px;
    cursor: pointer;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
    color: #000;
    &:not(:last-child) {
      margin-right: 44px;
    }
    &-name {
      font-size: 20px;
    }
    &:hover,
    &.active {
      background: #4978fa;
      color: #fff;
    }
  }
}
</style>
